<template lang="html">
  <section class="dossiers documentos categoriascolumn-start">
    <router-link :to="{ name: 'home'}" class="volver">Volver al listado</router-link>
    <h2 class="subtitulo-documentos row-center">
      <span></span>Dossieres<span></span>
    </h2>
    <section class="contenedor-buscador row-center">
      <input
        class="input-styled"
        type="text"
        placeholder="Buscar por nombre"
        id="searchTool"
        v-model="textSearch"
        @keyup="searchTimeOutSearch"
      />
      <!-- <button @click="search" class="btn-search fa fa-search">Buscar</button> -->
    </section>
    <template v-if="loading">
      <p class="resultados-busqueda">Cargando...</p>
    </template>
    <template v-else>
      <template v-if="Object.keys(dossiers).length != 0">
        <ul class="listado-documentos row-center fww aifs">
          <fileEl v-for="file in dossiers" :key="file.slug" :fileContent="file">
          </fileEl>
        </ul>
        <div class="row-center" v-if="DossiersMaxItems != Object.keys(dossiers).length">
          <span @click="scrollEnd" class="ver">Cargar más</span>
        </div>
      </template>
      <template v-else>
        <div>
          <p class="resultados-busqueda">No se han encontrado resultados.</p>
        </div>
      </template>
    </template>
     <template v-if="loading">
       <div class="fixed-loading">
          <img src="/img/loading.gif" alt="">
        </div>
      </template>
  </section>
</template>

<script lang="js">
  import { mapGetters, mapActions } from 'vuex';
  import fileEl from '@/components/file-el.vue'
  export default  {
    name: 'dossiers',
    props: [],
    components:{fileEl},
    mounted () {
      this.loadDossiers({ textFilter: this.textSearch, page: this.page })
    },
    data () {
      return {
        textSearch: "",
        timer : null,
        page: 1
      }
    },
    methods: {
    ...mapActions({
        loadDossiers : 'contents/loadDossiers'
      }),
      searchTimeOutSearch() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = setTimeout(() => {
            this.loadDossiers({ textFilter: this.textSearch, page: this.page })
        }, 1000);
      },
       scrollEnd() {
        this.page++;
        this.loadDossiers({ textFilter: this.textSearch, page: this.page });
      }
    },
    computed: {
      ...mapGetters({
        dossiers : 'contents/getDossier',
        loading : 'contents/getLoading',
        getDossiersMaxItems : 'contents/getDossiersMaxItems'
      }),
      DossiersMaxItems() {
      return this.getDossiersMaxItems;
      }
    },
    watch: {
      'textSearch'(){
        if(this.textSearch == ""){
          this.loadDossiers()
        }
      }
    }
}
</script>

<style scoped>
.dossiers {
}
</style>
